import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';

import BackgroundImage from 'gatsby-background-image';
//For Converting To Background Image
import { getImage } from "gatsby-plugin-image"
import { convertToBgImage } from "gbimage-bridge"

const Wrapper = styled.div`

    display: flex;
    align-items: center;
    justify-content: center;

    height: 600px;

    margin-top: 2.5vh;
    margin-bottom: 5vh;

    .title{
        font-size: 42px;
        color: #2D2B2C;
        margin-bottom: 45px;

        z-index: 2;

        width: 45%;
    }

    .delivery{
        font-size: 52px;
        z-index: 2;
    }

    .subtitle{
        font-size: 26px;
        color: #2D2B2C;
        margin-bottom: 45px;
        z-index: 2;
        width: 32.5%;
    }

    .hoc-button{
        text-align: center;
    }

    .large-shop-now{
        width: 35%;
        z-index: 2;

        &:hover{
            outline: 1px solid var(--darkgreen);
        }
    }

    .small-shop-now{
        width: fit-content;
        z-index: 2;

        &:hover{
            outline: 1px solid var(--darkgreen);
        }
    }

    @media(max-width: 1000px){
        flex-direction: column;

        height: fit-content;
        width: 100vw;

        margin: 1.5vh 0;

        .title{
            font-size: 36px;
            margin-bottom: 10px;
            width: 55%;
        }

        .delivery{
            font-size: 48px;
        }

        .subtitle{
            font-size: 24px;
            width: 100%;
        }

        .large-shop-now{
            width: 100%;
        }
    }
`;

const LargerImage = styled(BackgroundImage)`
    border-radius: 15px;
    overflow: hidden;

    height: 600px;
    width: 62vw;

    display: flex;
    flex-direction: column;
    justify-content: center;

    padding: 0 2.5%;

    color: black;

    @media(max-width: 1000px){
        display: none;
    }
`;

const LargerImageMobile = styled(BackgroundImage)`
    position: relative;

    border-radius: 15px;
    overflow: hidden;

    height: 600px;

    display: none;
    flex-direction: column;

    padding: 0 2.5%;

    color: black;
    width: 92.5vw;

    padding: 15% 10%;
    margin: 1vh 0;

    justify-content: flex-start;
    align-items: center;

    background-size: contain;
    background-position-y: bottom;
    background-position-x: right;
    background-size: 175%;

    @media(max-width: 1000px){
        display: flex;
    }
`;

const ShadowMobile = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    background: linear-gradient(rgba(205,208,212, 1) 275px, rgba(205,208,212, 0) 350px);
`;

const SmallerImage = styled(BackgroundImage)`
    border-radius: 15px;
    overflow: hidden;

    height: 290px;
    width: 35vw;

    display: flex;
    flex-direction: column;
    justify-content: center;

    padding: 0 5%;

    @media(max-width: 1000px){
        margin: 1vh 0;

        width: 92.5vw;
        padding: 10% 7.5%;

        justify-content: center;
    }
`;

const Section = styled.div`
    height: 100%;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-left: 1vw;
`

export default function Hero({ largeHero, deliveryHero, specialsHero })
{
    const largeImage = getImage(largeHero?.Image?.localFile?.childImageSharp?.gatsbyImageData);
    const largeBgImage = convertToBgImage(largeImage);

    const deliveryImage = getImage(deliveryHero?.Image?.localFile?.childImageSharp?.gatsbyImageData);
    const deliveryBgImage = convertToBgImage(deliveryImage);

    const specialsImage = getImage(specialsHero?.Image?.localFile?.childImageSharp?.gatsbyImageData);
    const specialsBgImage = convertToBgImage(specialsImage);

    return(
        <Wrapper>
            <LargerImage
                Tag="section"
                {...largeBgImage}
            >
                <h1 className="title">{largeHero.Title}</h1>

                <h2 className="subtitle">{largeHero.Subtitle}</h2>

                <Link to={largeHero.ButtonLink} className="hoc-button large-shop-now">{largeHero.ButtonLabel}</Link>
            </LargerImage>

            <LargerImageMobile
                Tag="section"
                alt={"!313"}
                {...largeBgImage}
                style={{
                    backgroundPositionX: '100%'
                }}
            >
                <ShadowMobile></ShadowMobile>
                <h1 className="title" style={{ width: '100%' }} >{largeHero.Title}</h1>

                <h2 className="subtitle">{largeHero.Subtitle}</h2>

                <Link to={largeHero.ButtonLink} className="hoc-button large-shop-now">{largeHero.ButtonLabel}</Link>
            </LargerImageMobile>

            <Section>
                <SmallerImage {...deliveryBgImage}>
                    <h2 className="title">{deliveryHero.Title}</h2>
                    <Link to={deliveryHero.ButtonLink} className="hoc-button small-shop-now">{deliveryHero.ButtonLabel}</Link>
                </SmallerImage>
                <SmallerImage {...specialsBgImage}>
                    <h2 className="title">{specialsHero.Title}</h2>
                    <Link to={specialsHero.ButtonLink} className="hoc-button small-shop-now">{specialsHero.ButtonLabel}</Link>
                </SmallerImage>
            </Section>
        </Wrapper>
    )
}