import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
import Img from "gatsby-image"

import SlidingBanner from '../Other/SlidingBanner'

const Wrapper = styled.div`
    margin: 7.5vh 0;

    display: flex;
    justify-content: space-around;
    align-items: center;

    @media(max-width: 1000px){
        flex-direction: column;
        justify-content: flex-start;

        margin: 2vh 0;
    }
`;

const BrandSection = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;

    width: 23vw;

    .image-wrapper{
        position: relative;

        .hover-background{
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;

            opacity: 0;

            transition: 300ms opacity;

            background: linear-gradient(0deg, #A1C654 0%, rgba(176, 230, 64, 0) 100%);
        }

        &:hover{
            cursor: pointer;

            .hover-background{
                opacity: 1;
            }
        }
    }

    .title{
        text-transform: capitalize;
        color: black;
        font-size: 36px;
        font-weight: bold;

        margin-top: 2vh;
    }

    .hoc-button-outline{
        text-align: center;

        color: black;

        width: 100%;

        &:hover{
            color: white;
        }
    }

    @media(max-width: 1000px){
        width: 95vw;

        margin: 3.75vh 0;
    }
`;

const BrandImage = styled(Img)`
    width: 23vw;
    border-radius: 5px;

    @media(max-width: 1000px){
        width: 95vw;
    }
`;


export default function FeaturedBrands({ brandBlocks }){
    brandBlocks = [];

    return (
        brandBlocks?.length > 0 ? (
        <>
            <SlidingBanner message={"featured brands"} numToRepeat={20} fontSize={'25px'}/>
            <Wrapper>
                {brandBlocks.map((_brandData) => {
                    return(
                        <BrandSection key={`${_brandData.Title}-${_brandData.id}`}>
                            <div className="image-wrapper">
                                <BrandImage fluid={_brandData?.Image?.localFile?.childImageSharp?.fluid} style={{ maxWidth: 'none' }}/>
                                <div className="hover-background"></div>
                            </div>

                            <h4 className="title">{_brandData.Title}</h4>
                            <p>{_brandData.Subtitle}</p>

                            <Link to={_brandData.ButtonLink} className="hoc-button-outline">{_brandData.ButtonLabel}</Link>
                        </BrandSection>
                    )
                })}
            </Wrapper>
        </>
        )
        :
        null
    );
}