import React from "react";
import styled from "styled-components";

import { Link } from 'gatsby';

import BackgroundImage from 'gatsby-background-image';
//For Converting To Background Image
import { getImage } from "gatsby-plugin-image"
import { convertToBgImage } from "gbimage-bridge"

//Effect Logos
import happyLogo from '../../assets/images/index/effects/happyLogo.png'
import energeticLogo from '../../assets/images/index/effects/energeticLogo.png'
import calmLogo from '../../assets/images/index/effects/calmLogo.png'
import sleepyLogo from '../../assets/images/index/effects/sleepyLogo.png'
import creativeLogo from '../../assets/images/index/effects/creativeLogo.png'

//Dark Effect Logos
import happyLogoDark from '../../assets/images/index/effects/happyLogoDark.png'
import energeticLogoDark from '../../assets/images/index/effects/energeticLogoDark.png'
import calmLogoDark from '../../assets/images/index/effects/calmLogoDark.png'
import sleepyLogoDark from '../../assets/images/index/effects/sleepyLogoDark.png'
import creativeLogoDark from '../../assets/images/index/effects/creativeLogoDark.png'

//Effect Gifs
// import happyGif from '../../assets/images/index/effects/happy.gif'
// import energeticGif from '../../assets/images/index/effects/energetic.gif'
// import calmGif from '../../assets/images/index/effects/calm.gif'
// import sleepyGif from '../../assets/images/index/effects/sleepy.gif'
// import creativeGif from '../../assets/images/index/effects/creative.gif'

const Wrapper = styled.div`
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 10px;
    grid-auto-rows: 330px;

    width: 100%;

    margin: 5vh 0.5vw;

    @media(max-width: 1000px){
        display: none;
    }
`;

const EffectSection = styled(BackgroundImage)`
    grid-column-start: ${({ colStart }) => colStart};
    grid-column-end: ${({ colEnd }) => colEnd};
    grid-row-start: ${({ rowStart }) => rowStart};
    grid-row-end: ${({ rowEnd }) => rowEnd};

    position: relative;

    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    padding: 5% 7.5%;
    margin: 1vh 0;

    height: 100%;
    width: 100%;

    border-radius: 10px;
    overflow: hidden;

    .effect{
        z-index: 5;

        display: flex;
        align-items: center;

        margin-bottom: 5%;

        h2{
            color: white;
            margin-right: 10px;
        }
    }

    .hoc-button{
        visibility: hidden;
        opacity: 0;
        z-index: 2;

        background: white;
        color: black;

        width: fit-content;

        padding: 5% 10%;

        transition: opacity 250ms;
    }


    .overlay{
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        background: linear-gradient(var(--darkgreen), 35%, rgba(0, 0, 0 ,0));
        z-index: 5;

        opacity: 0;
        transition: opacity 500ms;
    }

    .overlay-gif{
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;

        z-index: 4;
        opacity: 0;
    }

    &:hover{
        cursor: pointer;
        
        .hoc-button{
            z-index: 5;
            visibility: visible;
            opacity: 1;
        }

        .overlay, .overlay-gif{
            opacity: 1;
        }
    }
`;

export default function ShopEffectsDesktop({ smallTopLeft, smallBottomLeft, largeMiddle, smallTopRight, smallBottomRight })
{
    const smallTopLeftImage = getImage(smallTopLeft?.Icon?.localFile?.childImageSharp?.gatsbyImageData);
    const smallTopLeftBgImage = convertToBgImage(smallTopLeftImage);

    const largeMiddleImage = getImage(largeMiddle?.Icon?.localFile?.childImageSharp?.gatsbyImageData);
    const largeMiddleBgImage = convertToBgImage(largeMiddleImage);

    const smallBottomLeftImage = getImage(smallBottomLeft?.Icon?.localFile?.childImageSharp?.gatsbyImageData);
    const smallBottomLeftBgImage = convertToBgImage(smallBottomLeftImage);

    const smallTopRightImage = getImage(smallTopRight?.Icon?.localFile?.childImageSharp?.gatsbyImageData);
    const smallTopRightBgImage = convertToBgImage(smallTopRightImage);

    const smallBottomRightImage = getImage(smallBottomRight?.Icon?.localFile?.childImageSharp?.gatsbyImageData);
    const smallBottomRightBgImage = convertToBgImage(smallBottomRightImage);

    return(
        <Wrapper>
            <EffectSection
                {...smallTopLeftBgImage}
                style={{ backgroundSize: 'cover' }}
                colStart={1}
                colEnd={2}
                rowStart={1}
                rowEnd={2}
            >
                {/* <img className="overlay-gif" src={happyGif}/> */}
                <div className="overlay"></div>

                <div className="effect">
                    <h2>{smallTopLeft.Title}</h2>
                    <img src={happyLogo} className="light"/>
                </div>
                
                <Link to={smallTopLeft.ButtonLink} className="hoc-button">{smallTopLeft.ButtonLabel}</Link>
            </EffectSection>

            <EffectSection
                {...largeMiddleBgImage}
                style={{ backgroundSize: 'cover' }}
                colStart={2}
                colEnd={3}
                rowStart={1}
                rowEnd={3}
            >
                {/* <img className="overlay-gif" src={energeticGif}/> */}
                <div className="overlay"></div>

                <div className="effect">
                    <h2>{largeMiddle.Title}</h2>
                    <img src={energeticLogo} className="light"/>
                </div>
                
                <Link to={largeMiddle.ButtonLink} className="hoc-button">{largeMiddle.ButtonLabel}</Link>
            </EffectSection>

            <EffectSection
                {...smallBottomLeftBgImage}
                style={{ backgroundSize: 'cover' }}
                colStart={1}
                colEnd={2}
                rowStart={2}
                rowEnd={3}
            >
                {/* <img className="overlay-gif" src={calmGif}/> */}
                <div className="overlay"></div>

                <div className="effect">
                    <h2>{smallBottomLeft.Title}</h2>
                    <img src={calmLogo} className="light"/>
                </div>
                
                <Link to={smallBottomLeft.ButtonLink} className="hoc-button">{smallBottomLeft.ButtonLabel}</Link>
            </EffectSection>

            <EffectSection
                {...smallTopRightBgImage}
                style={{ backgroundSize: 'cover' }}
                colStart={3}
                colEnd={4}
                rowStart={1}
                rowEnd={2}
            >
                {/* <img className="overlay-gif" src={sleepyGif}/> */}
                <div className="overlay"></div>

                <div className="effect">
                    <h2>{smallTopRight.Title}</h2>
                    <img src={sleepyLogo} className="light"/>
                </div>
                
                <Link to={smallTopRight.ButtonLink} className="hoc-button">{smallTopRight.ButtonLabel}</Link>
            </EffectSection>

            <EffectSection
                {...smallBottomRightBgImage}
                style={{ backgroundSize: 'cover' }}
                colStart={3}
                colEnd={4}
                rowStart={2}
                rowEnd={3}
            >
                {/* <img className="overlay-gif" src={creativeGif}/> */}
                <div className="overlay"></div>

                <div className="effect">
                    <h2>{smallBottomRight.Title}</h2>
                    <img src={creativeLogo} className="light"/>
                </div>
                
                <Link to={smallBottomRight.ButtonLink} className="hoc-button">{smallBottomRight.ButtonLabel}</Link>
            </EffectSection>
        </Wrapper>
    )
}