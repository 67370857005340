import React from 'react';
import {Link} from 'gatsby';
import styled from 'styled-components';


export default function ShopButton(){

    const MOBILE_BREAKPOINT = "768px";

    const ShopButtonStyle = styled.div`
        bottom: 70px;
        right: 70px;
        position: fixed;
        z-index: 100;

        background-color: black;
        padding: 25px 20px;
        color: white;

        font-family: Oswald;

        &:hover{
            color: black;
            background-color: white;
            outline: 4px solid black;
        }

        @media screen and (max-width: ${MOBILE_BREAKPOINT}) {
            display: none;
        }
    `;

    return(
        <ShopButtonStyle>
            <Link to="/menu">SHOP NOW</Link>
        </ShopButtonStyle>
    )
}