import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
import BrandTile from './BrandTile';

const Wrapper = styled.div`
  width: 100%;
  min-height: 70vh;
  padding: 6vh 0px;
`;

const Header = styled.div`
  text-align: center;
  padding: 30px 10px;
  h3 {
    padding: 8px 0px;
  }
`;

const BrandGridStyle = styled.div`
  padding: 50px 10px;
  padding-top: 0px;

  .p-0 {
    padding: 0;
  }

  width: 1500px;
  position: relative;
  left: 50%;
  margin-left: -750px;
  @media (max-width: 1500px) {
    width: 1000px;
    margin-left: -500px;
  }
  @media (max-width: 1000px) {
    width: 100%;
    margin-left: -50%;
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

export default function BrandGrid({ branddata }) {
  return (
    <Wrapper>
      <Header>
        <h2>{branddata.Title}</h2>
        <h3>{branddata.Subtitle}</h3>
      </Header>
      <BrandGridStyle>
        <div className="container-fluid">
          <div className="row">
            {branddata.BrandCard.map((brand, index) => (
              <div className="col-lg-3 col-4 p-0" key={index}>
                <BrandTile
                  image={
                    brand?.logo?.localFile?.childImageSharp?.gatsbyImageData
                  }
                  link={brand?.brand_link}
                />
              </div>
            ))}
          </div>
        </div>
      </BrandGridStyle>
      <ButtonWrapper>
        <Link to={branddata.Button.Custom_Link_Slug}>
          <button className="hoc-button-outline">
            {branddata.Button.Label}
          </button>
        </Link>
      </ButtonWrapper>
    </Wrapper>
  );
}
