import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
import { BgImage } from 'gbimage-bridge';


const MOBILE_BREAKPOINT = '768px'

const Wrapper = styled.div`
  margin: 70px 30px;
  .bg-image,
  .bg-image::after,
  .bg-image::before{
    width: 100%;
    height: 100%;
    background-size: cover;
    @media (max-width: ${MOBILE_BREAKPOINT}){
      width: 100%;
    }
  }

    @media (max-width: 1000px){
      .container-fluid{
          padding: 0;
      }
      .bg-image-bottom{
          margin-top: 70px;
      }
    }
`;

const Container = styled.div`
  padding: 47px 80px;
  text-align: ${props => props.center ? 'center' : 'left'};
  min-height:45vh;
  .hoc-button {
    display: inline-block;
    padding: 20px 3rem;
  }
  @media screen and (max-width: 1200px) {
    padding: 47px 40px;
  }
  @media screen and (max-width: ${MOBILE_BREAKPOINT}) {
    min-height: 420px;
    padding: ${props => props.center ? '50px 40px 60px' : '30px 100px 140px 30px'};
  }
`;

const Title = styled.h3`
  margin-bottom: 30px;
  font-family: 'Oswald';
  font-style: normal;
  font-weight: 500;
  font-size: 48px;
  line-height: 100%;
  color: white;
  @media screen and (max-width: ${MOBILE_BREAKPOINT}) {
    font-size: 36px;
  }
`;

const Description = styled.div`
  max-width: 400px;
  margin: 0 ${props => props.center && 'auto'};
  margin-bottom: 30px;
  font-family: 'Lato';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 120%;
  color: white;
  @media screen and (max-width: ${MOBILE_BREAKPOINT}) {
    width: auto;
    font-size: 14px;
  }
`;

export default function BlogNShop({ leftdata, rightdata, center}){
    const leftImage = leftdata.article.BannerImage.localFile.childImageSharp.gatsbyImageData
    const rightImage = rightdata.article.BannerImage.localFile.childImageSharp.gatsbyImageData
    return(
      <Wrapper>
        <div className="container-fluid">
            <div className="row">
                <div className="col-lg-4 col-12">
                <BgImage
                    image={leftImage}
                    preserveStackingContext
                    className="bg-image"
                    style={{
                        width: '',
                        height: '',
                        backgroundPosition: 'center',
                        borderRadius: '3px',
                        overflow: 'hidden',
                        backgroundSize: '',
                    }}
                >
                    <Container center={center}>
                        <Title>{leftdata.article.Title}</Title>
                        <Description center={center}>{leftdata.article.Preview_Text}</Description>
                       {/* {`/blog/`+leftdata.article.slug} */}
                        <Link to="/menu" className="hoc-button">read article</Link>
                    </Container>
                </BgImage>
                </div>
                <div className="col-lg-8 col-12" style={{ overflow: 'hidden' }}>
                <BgImage
                    image={rightImage}
                    preserveStackingContext
                    className="bg-image bg-image-bottom"
                    style={{
                        width: '',
                        height: '',
                        backgroundPosition: 'center',
                        borderRadius: '3px',
                        overflow: 'hidden',
                        backgroundSize: '',
                    }}
                >
                    <Container center={center}>
                        <Title>{rightdata.article.Title}</Title>
                        <Description center={center}>{rightdata.article.Preview_Text}</Description>
                        <Link to={rightdata.Button_Link_Slug} className="hoc-button">Shop Now</Link>
                    </Container>
                </BgImage>
                </div>
            </div>
        </div>
       
      </Wrapper>
    );
}