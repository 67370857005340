import React from "react";
import styled from "styled-components";

import { Link } from 'gatsby';

import BackgroundImage from 'gatsby-background-image';
//For Converting To Background Image
import { getImage } from "gatsby-plugin-image"
import { convertToBgImage } from "gbimage-bridge"

//Effect Logos
import happyLogo from '../../assets/images/index/effects/happyLogo.png'
import energeticLogo from '../../assets/images/index/effects/energeticLogo.png'
import calmLogo from '../../assets/images/index/effects/calmLogo.png'
import sleepyLogo from '../../assets/images/index/effects/sleepyLogo.png'
import creativeLogo from '../../assets/images/index/effects/creativeLogo.png'

const Wrapper = styled.div`
    display: none;

    flex-direction: column;
    align-items: center;

    width: 100%;
    margin: 2.5vh 0;

    @media(max-width: 1000px){
        display: flex;
    }
`;

const EffectSection = styled(BackgroundImage)`
    position: relative;

    display: flex;
    flex-direction: column;
    justify-content: space-between;

    padding: 5% 2.5rem;
    margin: 1vh 0;

    height: 240px;
    width: 95vw;

    border-radius: 10px;
    overflow: hidden;

    .effect{
        //color: #2D2B2C;
        z-index: 3;

        display: flex;
        align-items: center;

        h2{
            color: white;
            margin-right: 10px;
        }
    }

    .hoc-button{
        //visibility: hidden;
        //opacity: 0;
        z-index: 3;

        background: white;
        color: black;

        width: fit-content;

        padding: 2rem 4rem;

        transition: opacity 250ms;
    }

    &:hover{
        cursor: pointer;

        /* .hoc-button{
            opacity: 1;
            visibility: visible;
        } */
    }
`;

const ImageGradient = styled.div`
    position: absolute;

    top: 0;
    left: 0;
    height: 100%;
    width: 100%;

    background: linear-gradient(var(--darkgreen), 35%, rgba(0, 0, 0 ,0));

    z-index: 2;
`;

export default function ShopEffectsMobile({ smallTopLeft, smallBottomLeft, largeMiddle, smallTopRight, smallBottomRight })
{
    const smallTopLeftImage = getImage(smallTopLeft?.Icon?.localFile?.childImageSharp?.gatsbyImageData);
    const smallTopLeftBgImage = convertToBgImage(smallTopLeftImage);

    const largeMiddleImage = getImage(largeMiddle?.Icon?.localFile?.childImageSharp?.gatsbyImageData);
    const largeMiddleBgImage = convertToBgImage(largeMiddleImage);

    const smallBottomLeftImage = getImage(smallBottomLeft?.Icon?.localFile?.childImageSharp?.gatsbyImageData);
    const smallBottomLeftBgImage = convertToBgImage(smallBottomLeftImage);

    const smallTopRightImage = getImage(smallTopRight?.Icon?.localFile?.childImageSharp?.gatsbyImageData);
    const smallTopRightBgImage = convertToBgImage(smallTopRightImage);

    const smallBottomRightImage = getImage(smallBottomRight?.Icon?.localFile?.childImageSharp?.gatsbyImageData);
    const smallBottomRightBgImage = convertToBgImage(smallBottomRightImage);

    return(
        <>
            <Wrapper>
                <EffectSection
                    {...smallTopLeftBgImage}
                    style={{ backgroundSize: 'cover' }}
                >
                    <ImageGradient></ImageGradient>

                    <div className="effect">
                        <h2>{smallTopLeft.Title}</h2>
                        <img src={happyLogo} className="light"/>
                    </div>
                
                    <Link to={smallTopLeft.ButtonLink} className="hoc-button">{smallTopLeft.ButtonLabel}</Link>
                </EffectSection>

                <EffectSection
                    {...largeMiddleBgImage}
                    style={{ backgroundSize: 'cover' }}
                >
                    <ImageGradient></ImageGradient>

                    <div className="effect">
                        <h2>{largeMiddle.Title}</h2>
                        <img src={energeticLogo}/>
                    </div>
                    
                    <Link to={largeMiddle.ButtonLink} className="hoc-button">{largeMiddle.ButtonLabel}</Link>
                </EffectSection>

                <EffectSection
                    {...smallBottomLeftBgImage}
                    style={{ backgroundSize: 'cover' }}
                >
                    <ImageGradient></ImageGradient>

                    <div className="effect">
                        <h2>{smallBottomLeft.Title}</h2>
                        <img src={calmLogo}/>
                    </div>
                    
                    <Link to={smallBottomLeft.ButtonLink} className="hoc-button">{smallBottomLeft.ButtonLabel}</Link>
                </EffectSection>

                <EffectSection
                    {...smallTopRightBgImage}
                    style={{ backgroundSize: 'cover' }}
                >
                    <ImageGradient></ImageGradient>

                    <div className="effect">
                        <h2>{smallTopRight.Title}</h2>
                        <img src={sleepyLogo}/>
                    </div>
                    
                    <Link to={smallTopRight.ButtonLink} className="hoc-button">{smallTopRight.ButtonLabel}</Link>
                </EffectSection>

                <EffectSection
                    {...smallBottomRightBgImage}
                    style={{ backgroundSize: 'cover' }}
                >
                    <ImageGradient></ImageGradient>

                    <div className="effect">
                        <h2>{smallBottomRight.Title}</h2>
                        <img src={creativeLogo}/>
                    </div>
                    
                    <Link to={smallBottomRight.ButtonLink} className="hoc-button">{smallBottomRight.ButtonLabel}</Link>
                </EffectSection>
            </Wrapper>
        </>
    )
}