import React from 'react';
import styled from 'styled-components';
import { StaticImage } from 'gatsby-plugin-image';

export default function Payment() {
  const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    background: #f5f5f5;
  `;

  const Title = styled.p`
    font-family: Lato;
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 130%;
  `;

  const Wrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;

    .payimg {
      margin: 15px 10px;
    }
  `;
  return (
    <Container>
      <Title>PAYMENT METHODS</Title>
      <Wrapper>
        <StaticImage
          className="payimg"
          src="../../assets/images/payment/visa.png"
          alt=""
        />
        <StaticImage
          className="payimg"
          src="../../assets/images/payment/master.png"
          alt=""
        />
        <StaticImage
          className="payimg"
          src="../../assets/images/payment/visa-debit.png"
          alt=""
        />
        <StaticImage
          className="payimg"
          src="../../assets/images/payment/master-debit.png"
          alt=""
        />
      </Wrapper>
    </Container>
  );
}
