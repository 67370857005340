import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
import { BgImage } from 'gbimage-bridge';


const MOBILE_BREAKPOINT = '768px'

const Wrapper = styled.div`
  margin: 70px 30px;
  .bg-image,
  .bg-image::after,
  .bg-image::before{
    width: 100%;
    height: 100%;
    background-size: cover;
    @media (max-width: ${MOBILE_BREAKPOINT}){
      width: 100%;
    }
  }
`;

const Container = styled.div`
  padding: 47px 80px;
  text-align: ${props => props.center ? 'center' : 'left'};
  .hoc-button {
    display: inline-block;
    padding: 20px 3rem;
  }
  @media screen and (max-width: ${MOBILE_BREAKPOINT}) {
    min-height: 320px;
    padding: ${props => props.center ? '50px 40px 60px' : '30px 100px 140px 30px'};
  }
`;

const Title = styled.h3`
  margin-bottom: 30px;
  font-family: 'Oswald';
  font-style: normal;
  font-weight: 500;
  font-size: 48px;
  line-height: 100%;
  color: white;
  @media screen and (max-width: ${MOBILE_BREAKPOINT}) {
    font-size: 36px;
  }
`;

const Description = styled.div`
  width: 400px;
  margin: 0 ${props => props.center && 'auto'};
  margin-bottom: 30px;
  font-family: 'Lato';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 120%;
  color: white;
  @media screen and (max-width: ${MOBILE_BREAKPOINT}) {
    width: auto;
    font-size: 14px;
  }
`;

export default function BannerTypeA({ title, description, bannerImage, center, link, buttonLabel }){

    return(
      <Wrapper>
        <BgImage
          image={bannerImage}
          preserveStackingContext
          className="bg-image"
          style={{
            width: '',
            height: '',
            backgroundPosition: 'center',
            borderRadius: '3px',
            overflow: 'hidden',
            backgroundSize: '',
          }}
        >
          <Container center={center}>
            <Title>{title}</Title>
            <Description center={center}>{description}</Description>
            <Link className="hoc-button" to={link}>{buttonLabel}</Link>
          </Container>
        </BgImage>
      </Wrapper>
    );
}