import React from 'react';
import styled from 'styled-components';
import { GatsbyImage } from 'gatsby-plugin-image';

const BrandTileStyle = styled.a`
  background-color: white;
  height: 15vw;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 5px 5px;
  padding: 20px 20px;
  border-radius: 3px;
  @media (max-width: 1000px) {
    height: 25vw;
  }
`;

const BrandTile = ({ image, link }) => (
  <BrandTileStyle href={link}>
    <GatsbyImage image={image} alt="" />
  </BrandTileStyle>
);

export default BrandTile;
