import React, { useContext, useEffect } from 'react';

import { graphql } from 'gatsby';
import styled from 'styled-components';
import { CheckoutContext } from '../components/apolloMenu/contexts/checkout';
import SEO from '../components/SEO/SEO';
import BannerJoin from '../components/Banners/BannerJoin';
import BrandGrid from '../components/Brands/BrandGrid';
import BannerTypeA from '../components/Banners/BannerTypeA';
import Payment from '../components/Homepage/Payment';
import BlogNShop from '../components/Blog/BlogNShop';
import ShopButton from '../components/Homepage/ShopNow';
import Hero from '../components/Homepage/Hero';
import FeaturedBrands from '../components/Homepage/FeaturedBrands';
import ShopEffectsMobile from '../components/Homepage/ShopEffectsMobile';
import ShopEffectsDesktop from '../components/Homepage/ShopEffectsDesktop';
import ShopCategories from '../components/Homepage/ShopCategories';

import { purchaseSurf } from '../hooks/useSurfside';

const CatDemo = styled.div`
  padding: 30px 30px;
  outline: solid blue 5px;
  h1 {
    color: black;
  }
`;

export default function HomePage({ data, location }) {
  // const hero_type_a = data.strapiHomepage.Hero_Type_A;
  // const brand_grid = data.strapiHomepage.Brands_Grid;
  // const branner_join = data.strapiHomepage.Join_Banner
  // const banner_type_middle = data.strapiHomepage.Blog_Banner;
  // const banner_type_bottom = data.strapiHomepage.Blog_Banner_Bottom;

  // const blog_and_shop = data.strapiHomepage.Blog_And_Shop_Banner;
  // const category = data.strapiHomepage.Category_Version_2;

  // New Strapi Data
  const joinBanner = data?.strapiHomepageDelivery?.Join_Banner;

  const topBlogBanner = data?.strapiHomepageDelivery?.Blog_Banner_Top;
  const leftBlogBanner = data?.strapiHomepageDelivery?.Blog_Banner_Left;
  const rightBlogBanner = data?.strapiHomepageDelivery?.Blog_Banner_Right;
  const bottomBlogBanner = data?.strapiHomepageDelivery?.Blog_Banner_Bottom;

  const brandGrid = data?.strapiHomepageDelivery?.Brand_Grid;

  const { checkout, currentRetailer } = useContext(CheckoutContext);

  const schema = {
    '@context': 'https://schema.org',
    '@type': 'Store',
    name: 'House of Cannabis',
    image: '/logo.jpg',
    '@id': '',
    url: 'https://thehousecannabis.ca/',
    telephone: '(519)-714-0420',
    priceRange: '$',
    address: {
      '@type': 'PostalAddress',
      streetAddress: '404 Spadina Avenue',
      addressLocality: 'Toronto',
      addressRegion: 'ON',
      postalCode: 'M5T 2G7',
      addressCountry: 'CA',
    },
    geo: {
      '@type': 'GeoCoordinates',
      latitude: 43.6559931,
      longitude: -79.4018524,
    },
    openingHoursSpecification: [
      {
        '@type': 'OpeningHoursSpecification',
        dayOfWeek: [
          'Monday',
          'Tuesday',
          'Wednesday',
          'Thursday',
          'Friday',
          'Saturday',
        ],
        opens: '10:00',
        closes: '21:00',
      },
      {
        '@type': 'OpeningHoursSpecification',
        dayOfWeek: 'Sunday',
        opens: '11:00',
        closes: '18:00',
      },
    ],
    department: [
      {
        '@type': 'Store',
        name: 'House of Cannabis - Toronto',
        url: 'https://thehousecannabis.ca/toronto/location',
        description:
          'Located at 404 Spadina Avenue, our staff takes pride in providing the best cannabis customer service around. If you have cannabis questions, we have answers!',
        address: {
          '@type': 'PostalAddress',
          streetAddress: '404 Spadina Avenue',
          addressLocality: 'Toronto',
          addressRegion: 'ON',
          postalCode: 'M5T 2G7',
          addressCountry: 'CA',
        },
        location: {
          '@type': 'Place',
          geo: {
            '@type': 'GeoCoordinates',
            latitude: 43.6559931,
            longitude: -79.4018524,
          },
        },
      },
      {
        '@type': 'Store',
        name: 'House of Cannabis - Barrie',
        url: 'https://thehousecannabis.ca/barrie/location',
        description:
          'Located at 66 Dunlop Street East in Barrie, we’re committed to ensuring you’re always able to find all the amazing weed accessories you’re looking for.',
        address: {
          '@type': 'PostalAddress',
          streetAddress: '66 Dunlop Street East',
          addressLocality: 'Barrie',
          addressRegion: 'ON',
          postalCode: 'L4M 1A4',
          addressCountry: 'CA',
        },
        location: {
          '@type': 'Place',
          geo: {
            '@type': 'GeoCoordinates',
            latitude: 44.3893954,
            longitude: -79.6897951,
          },
        },
      },
      {
        '@type': 'Store',
        name: 'House of Cannabis - Kitchener',
        url: 'https://thehousecannabis.ca/kitchener/location',
        description:
          'Located at 181 King Street West in Kitchener, House of Cannabis strives to provide the best quality cannabis products and weed accessories in Ontario.',
        address: {
          '@type': 'PostalAddress',
          streetAddress: '181 King Street West',
          addressLocality: 'Kitchener',
          addressRegion: 'ON',
          postalCode: 'N2G 1A7',
          addressCountry: 'CA',
        },
        location: {
          '@type': 'Place',
          geo: {
            '@type': 'GeoCoordinates',
            latitude: 43.4507155,
            longitude: -80.4946477,
          },
        },
      },
      {
        '@type': 'Store',
        name: 'House of Cannabis - Kingston',
        url: 'https://thehousecannabis.ca/kingston/location',
        description:
          'Located at 208 Division Street, our team’s unwavering commitment to offering the best customer service has helped make us Kingston’s #1 weed dispensary.',
        address: {
          '@type': 'PostalAddress',
          streetAddress: '208 Division Street',
          addressLocality: 'Kingston',
          addressRegion: 'ON',
          postalCode: 'K7K 3Z1',
          addressCountry: 'CA',
        },
        location: {
          '@type': 'Place',
          geo: {
            '@type': 'GeoCoordinates',
            latitude: 44.2342596,
            longitude: -76.4956145,
          },
        },
      },
      {
        '@type': 'Store',
        name: 'House of Cannabis - Kingston',
        url: 'https://thehousecannabis.ca/kingston/location',
        description:
          'Located at 208 Division Street, our team’s unwavering commitment to offering the best customer service has helped make us Kingston’s #1 weed dispensary',
        address: {
          '@type': 'PostalAddress',
          streetAddress: '208 Division Street',
          addressLocality: 'Kingston',
          addressRegion: 'ON',
          postalCode: 'K7K 3Z1',
          addressCountry: 'CA',
        },
        location: {
          '@type': 'Place',
          geo: {
            '@type': 'GeoCoordinates',
            latitude: 44.2342596,
            longitude: -76.4956145,
          },
        },
      },
    ],
  };


  //ON GTM
  // useEffect(() => {
  //   if (typeof window !== 'undefined') {
  //     if (location && location.search) {
  //       const isInUrl = location.search.includes('?checkout_id=');

  //       if (isInUrl) {
  //         const checkoutId = location.search.substring(13);

  //         if (checkout && checkoutId === checkout?.id) {
  //           const storename = data.strapiDispensaryIds.Store.filter(
  //             (x) => x.RetailerId === currentRetailer
  //           )[0].Name;
  //           setTimeout(purchaseSurf, 4000, storename, checkout);
  //         }
  //       }
  //     }
  //   }
  // }, [checkout]);

  return (
    <>
      <ShopButton />
      <SEO
        title="The House of Cannabis | Ontario’s Finest Weed Dispensaries"
        description={`Top-Quality Weed Accessories | Pre-Rolled Joints | Weed 
                Pipes & Bongs | Cannabis Concentrates | Weed Vaporizers & Vape Pens | 
                Ontario’s Best Weed Strains`}
        schemaMarkup={schema}
      />

      <Hero
        largeHero={data?.strapiHomepageDelivery?.Hero_Three_Block?.LargeBlock}
        deliveryHero={
          data?.strapiHomepageDelivery?.Hero_Three_Block?.SmallTopBlock
        }
        specialsHero={
          data?.strapiHomepageDelivery?.Hero_Three_Block?.SmallBottomBlock
        }
      />

      <FeaturedBrands
        first={data.firstBrand}
        second={data.secondBrand}
        third={data.thirdBrand}
        fourth={data.fourthBrand}
        brandBlocks={data?.strapiHomepageDelivery?.Featured_Brands?.BrandBlock}
      />

      <ShopEffectsMobile
        smallTopLeft={data?.strapiHomepageDelivery?.Effects?.SmallTopLeft}
        smallBottomLeft={data?.strapiHomepageDelivery.Effects?.SmallBottomLeft}
        largeMiddle={data?.strapiHomepageDelivery.Effects?.LargeMiddle}
        smallTopRight={data?.strapiHomepageDelivery.Effects?.SmallTopRight}
        smallBottomRight={
          data?.strapiHomepageDelivery.Effects?.SmallBottomRight
        }
      />
      <ShopEffectsDesktop
        smallTopLeft={data?.strapiHomepageDelivery?.Effects?.SmallTopLeft}
        smallBottomLeft={data?.strapiHomepageDelivery.Effects?.SmallBottomLeft}
        largeMiddle={data?.strapiHomepageDelivery.Effects?.LargeMiddle}
        smallTopRight={data?.strapiHomepageDelivery.Effects?.SmallTopRight}
        smallBottomRight={
          data?.strapiHomepageDelivery.Effects?.SmallBottomRight
        }
      />

      <ShopCategories
        largeSection={data?.strapiHomepageDelivery?.Categories?.Large}
        mediumLeftSection={data?.strapiHomepageDelivery?.Categories?.MediumLeft}
        mediumRightSection={
          data?.strapiHomepageDelivery?.Categories?.MediumRight
        }
        smallLeftSection={data?.strapiHomepageDelivery?.Categories?.SmallLeft}
        smallMiddleSection={
          data?.strapiHomepageDelivery?.Categories?.SmallMiddle
        }
        smallRightSection={data?.strapiHomepageDelivery?.Categories?.SmallRight}
      />

      <BannerJoin
        title={joinBanner.Main_Title}
        subtitle={joinBanner.Sub_Title}
        description={[joinBanner.Content]}
        image={joinBanner.Image.localFile?.childImageSharp?.gatsbyImageData}
        link="/"
      />

      <BannerTypeA
        title={topBlogBanner?.article?.Title}
        description={topBlogBanner?.article?.Preview_Text}
        bannerImage={
          topBlogBanner?.article?.BannerImage.localFile.childImageSharp
            .gatsbyImageData
        }
        link="/menu"
        buttonLabel="read article"
      />

      <BlogNShop leftdata={leftBlogBanner} rightdata={rightBlogBanner} center />

      <BannerTypeA
        title={bottomBlogBanner.Title}
        description={bottomBlogBanner.Content}
        bannerImage={
          bottomBlogBanner.Background_Image.localFile.childImageSharp
            .gatsbyImageData
        }
        link={bottomBlogBanner.Blog_Button_Link.Link_Slug}
        buttonLabel={bottomBlogBanner.Blog_Button_Link.Button_Label}
      />

      <BrandGrid branddata={brandGrid} />

      <Payment />
    </>
  );
}

export const query = graphql`
  query {
    strapiDispensaryIds {
      Store {
        Name
        DispensaryId
        CheckoutUrl
        RetailerId
      }
    }
    strapiHomepageDelivery {
      Hero_Three_Block {
        LargeBlock {
          ButtonLabel
          ButtonLink
          Subtitle
          Title
          Image {
            localFile {
              childImageSharp {
                gatsbyImageData(
                  quality: 100
                  placeholder: BLURRED
                  formats: [AUTO, WEBP, AVIF]
                )
              }
            }
          }
        }
        SmallBottomBlock {
          ButtonLabel
          ButtonLink
          Title
          Image {
            localFile {
              childImageSharp {
                gatsbyImageData(
                  quality: 100
                  placeholder: BLURRED
                  formats: [AUTO, WEBP, AVIF]
                )
              }
            }
          }
        }
        SmallTopBlock {
          ButtonLabel
          ButtonLink
          Title
          Image {
            localFile {
              childImageSharp {
                gatsbyImageData(
                  quality: 100
                  placeholder: BLURRED
                  formats: [AUTO, WEBP, AVIF]
                )
              }
            }
          }
        }
      }
      Featured_Brands {
        BrandBlock {
          ButtonLabel
          ButtonLink
          Subtitle
          Title
          id
          Image {
            localFile {
              childImageSharp {
                fluid(quality: 100) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
      Effects {
        LargeMiddle {
          ButtonLabel
          ButtonLink
          Title
          id
          Icon {
            localFile {
              childImageSharp {
                gatsbyImageData(
                  quality: 100
                  placeholder: BLURRED
                  formats: [AUTO, WEBP, AVIF]
                )
              }
            }
          }
        }
        SmallBottomLeft {
          ButtonLabel
          ButtonLink
          id
          Title
          Icon {
            localFile {
              childImageSharp {
                gatsbyImageData(
                  quality: 100
                  placeholder: BLURRED
                  formats: [AUTO, WEBP, AVIF]
                )
              }
            }
          }
        }
        SmallBottomRight {
          ButtonLabel
          ButtonLink
          id
          Title
          Icon {
            localFile {
              childImageSharp {
                gatsbyImageData(
                  quality: 100
                  placeholder: BLURRED
                  formats: [AUTO, WEBP, AVIF]
                )
              }
            }
          }
        }
        SmallTopLeft {
          ButtonLabel
          ButtonLink
          Title
          id
          Icon {
            localFile {
              childImageSharp {
                gatsbyImageData(
                  quality: 100
                  placeholder: BLURRED
                  formats: [AUTO, WEBP, AVIF]
                )
              }
            }
          }
        }
        SmallTopRight {
          ButtonLink
          ButtonLabel
          Title
          id
          Icon {
            localFile {
              childImageSharp {
                gatsbyImageData(
                  quality: 100
                  placeholder: BLURRED
                  formats: [AUTO, WEBP, AVIF]
                )
              }
            }
          }
        }
      }
      Categories {
        Large {
          ButtonLabel
          ButtonLink
          Title
          id
          Image {
            localFile {
              childImageSharp {
                gatsbyImageData(
                  quality: 100
                  placeholder: BLURRED
                  formats: [AUTO, WEBP, AVIF]
                )
              }
            }
          }
        }
        MediumLeft {
          ButtonLabel
          ButtonLink
          Title
          id
          Image {
            localFile {
              childImageSharp {
                gatsbyImageData(
                  quality: 100
                  placeholder: BLURRED
                  formats: [AUTO, WEBP, AVIF]
                )
              }
            }
          }
        }
        MediumRight {
          ButtonLabel
          ButtonLink
          Title
          id
          Image {
            localFile {
              childImageSharp {
                gatsbyImageData(
                  quality: 100
                  placeholder: BLURRED
                  formats: [AUTO, WEBP, AVIF]
                )
              }
            }
          }
        }
        SmallLeft {
          ButtonLink
          ButtonLabel
          Title
          id
          Image {
            localFile {
              childImageSharp {
                gatsbyImageData(
                  quality: 100
                  placeholder: BLURRED
                  formats: [AUTO, WEBP, AVIF]
                )
              }
            }
          }
        }
        SmallMiddle {
          ButtonLabel
          ButtonLink
          Title
          id
          Image {
            localFile {
              childImageSharp {
                gatsbyImageData(
                  quality: 100
                  placeholder: BLURRED
                  formats: [AUTO, WEBP, AVIF]
                )
              }
            }
          }
        }
        SmallRight {
          ButtonLabel
          ButtonLink
          Title
          id
          Image {
            localFile {
              childImageSharp {
                gatsbyImageData(
                  quality: 100
                  placeholder: BLURRED
                  formats: [AUTO, WEBP, AVIF]
                )
              }
            }
          }
        }
      }
      Join_Banner {
        Content
        Main_Title
        Sub_Title
        id
        Image {
          localFile {
            childImageSharp {
              gatsbyImageData(
                quality: 100
                placeholder: BLURRED
                formats: [AUTO, WEBP, AVIF]
              )
            }
          }
        }
      }
      Blog_Banner_Left {
        blog_post
        article {
          id
          Preview_Text
          Title
          slug
          BannerImage {
            localFile {
              childImageSharp {
                gatsbyImageData(
                  quality: 100
                  placeholder: BLURRED
                  formats: [AUTO, WEBP, AVIF]
                )
              }
            }
          }
        }
      }
      Blog_Banner_Right {
        article {
          slug
          id
          Title
          Preview_Text
          BannerImage {
            localFile {
              childImageSharp {
                gatsbyImageData(
                  quality: 100
                  placeholder: BLURRED
                  formats: [AUTO, WEBP, AVIF]
                )
              }
            }
          }
        }
      }
      Blog_Banner_Top {
        article {
          Preview_Text
          Title
          BannerImage {
            localFile {
              childImageSharp {
                gatsbyImageData(
                  quality: 100
                  placeholder: BLURRED
                  formats: [AUTO, WEBP, AVIF]
                )
              }
            }
          }
        }
      }
      Blog_Banner_Bottom {
        id
        Content
        Title
        Blog_Button_Link {
          Button_Label
          Link_Slug
          id
        }
        Background_Image {
          localFile {
            childImageSharp {
              gatsbyImageData(
                quality: 100
                placeholder: BLURRED
                formats: [AUTO, WEBP, AVIF]
              )
            }
          }
        }
      }
      Brand_Grid {
        BrandCard {
          brand_link
          id
          logo {
            localFile {
              childImageSharp {
                gatsbyImageData(
                  quality: 100
                  placeholder: BLURRED
                  formats: [AUTO, WEBP, AVIF]
                )
              }
            }
          }
        }
        Subtitle
        Title
        id
        Button {
          Custom_Link_Slug
          Label
          id
        }
      }
    }
  }
`;
