import React from 'react';
import styled from 'styled-components';

import BackgroundImage from 'gatsby-background-image';
//For Converting To Background Image
import { getImage } from "gatsby-plugin-image"
import { convertToBgImage } from "gbimage-bridge"

import { Link } from 'gatsby';

const Wrapper = styled.div`
    display: flex;
    justify-content: center;
    
    width: 100%;
`;

const Categorieswrapper = styled.div`
    display: grid;
    grid-template-columns: repeat(16, 1fr);
    gap: 10px;
    grid-auto-rows: 340px;

    width: 97.5%;
    margin: 2.5vh 0;

    @media(max-width: 1000px){
        display: flex;
        
        flex-direction: column;
        align-items: center;
    }
`

const CategorySection = styled(BackgroundImage)`
    grid-column-start: ${({ colStart }) => colStart};
    grid-column-end: ${({ colEnd }) => colEnd};
    grid-row-start: ${({ rowStart }) => rowStart};
    grid-row-end: ${({ rowEnd }) => rowEnd};

    height: 100%;
    width: 100%;

    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    padding: 12.5% 7.5%;
    margin: 1vh 0;

    border-radius: 10px;
    overflow: hidden;

    h3{
        font-family: Oswald;
        text-transform: capitalize;
        color: #2D2B2C;
        margin-right: 10px;
        font-size: 36px;
    }

    .hoc-button{
        visibility: hidden;
        opacity: 0;
        z-index: 3;

        width: fit-content;

        padding: 20px 40px;

        transition: opacity 250ms;

        &:hover{
            outline: 1px solid var(--darkgreen);
        }
    }

    &.large{
        padding-bottom: 85%;
    }

    &:hover{
        cursor: pointer;

        .hoc-button{
            opacity: 1;
            visibility: visible;
        }
    }

    @media(max-width: 1000px){
        height: 240px;
        width: 95vw;
        padding: 5% 2.5rem;

        &.large{
            padding-bottom: 5%;
        }

        .hoc-button{
            opacity: 1;
            visibility: visible;
        }
    }
`;

export default function ShopCategories({ largeSection, mediumLeftSection, mediumRightSection, smallLeftSection, smallMiddleSection, smallRightSection})
{
    const largeImage = getImage(largeSection?.Image?.localFile?.childImageSharp?.gatsbyImageData);
    const largeBgImage = convertToBgImage(largeImage);

    const mediumLeftImage = getImage(mediumLeftSection?.Image?.localFile?.childImageSharp?.gatsbyImageData);
    const mediumLeftBgImage = convertToBgImage(mediumLeftImage);

    const mediumRightImage = getImage(mediumRightSection?.Image?.localFile?.childImageSharp?.gatsbyImageData);
    const mediumRightBgImage = convertToBgImage(mediumRightImage);

    const smallLeftImage = getImage(smallLeftSection?.Image?.localFile?.childImageSharp?.gatsbyImageData);
    const smallLeftBgImage = convertToBgImage(smallLeftImage);

    const smallMiddleImage = getImage(smallMiddleSection?.Image?.localFile?.childImageSharp?.gatsbyImageData);
    const smallMiddleBgImage = convertToBgImage(smallMiddleImage);

    const smallRightImage = getImage(smallRightSection?.Image?.localFile?.childImageSharp?.gatsbyImageData);
    const smallRightBgImage = convertToBgImage(smallRightImage);

    return(
        <>

            <Wrapper>
                <Categorieswrapper>
                    <CategorySection
                        {...mediumLeftBgImage}
                        colStart={1}
                        colEnd={8}
                        rowStart={1}
                        rowEnd={2}
                    >
                        <h3>{mediumLeftSection.Title}</h3>

                        <Link to={mediumLeftSection.ButtonLink} className="hoc-button">{mediumLeftSection.ButtonLabel}</Link>
                    </CategorySection>

                    <CategorySection
                        {...mediumRightBgImage}
                        colStart={8}
                        colEnd={13}
                        rowStart={1}
                        rowEnd={2}
                    >
                        <h3>{mediumRightSection.Title}</h3>

                        <Link to={mediumRightSection.ButtonLink} className="hoc-button">{mediumRightSection.ButtonLabel}</Link>
                    </CategorySection>

                    <CategorySection
                        {...smallLeftBgImage}
                        colStart={1}
                        colEnd={5}
                        rowStart={2}
                        rowEnd={3}
                    >
                        <h3>{smallLeftSection.Title}</h3>

                        <Link to={smallLeftSection.ButtonLink} className="hoc-button">{smallLeftSection.ButtonLabel}</Link>
                    </CategorySection>

                    <CategorySection
                        {...smallMiddleBgImage}
                        colStart={5}
                        colEnd={9}
                        rowStart={2}
                        rowEnd={3}
                    >
                        <h3>{smallMiddleSection.Title}</h3>

                        <Link to={smallMiddleSection.ButtonLink} className="hoc-button">{smallMiddleSection.ButtonLabel}</Link>
                    </CategorySection>

                    <CategorySection
                        {...smallRightBgImage}
                        colStart={9}
                        colEnd={13}
                        rowStart={2}
                        rowEnd={3}
                    >
                        <h3>{smallRightSection.Title}</h3>

                        <Link to={smallRightSection.ButtonLink} className="hoc-button">{smallRightSection.ButtonLabel}</Link>
                    </CategorySection>

                    <CategorySection
                        {...largeBgImage}
                        colStart={13}
                        colEnd={17}
                        rowStart={1}
                        rowEnd={3}
                        className="large"
                    >
                        <h3>{largeSection.Title}</h3>

                        <Link to={largeSection.ButtonLink} className="hoc-button">{largeSection.ButtonLabel}</Link>
                    </CategorySection>
                </Categorieswrapper>
            </Wrapper>
        </>
    )

}