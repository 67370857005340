import React, { useContext } from 'react';
import styled from 'styled-components';
import { GatsbyImage } from 'gatsby-plugin-image';

import ModalsContext from '../../contexts/ModalsContext';

const MOBILE_BREAKPOINT = '992px';

const Container = styled.div`
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  grid-template-rows: 1fr auto auto auto 1fr;
  padding: 30px 0 30px 90px;
  background-color: white;

  @media screen and (max-width: ${MOBILE_BREAKPOINT}) {
    padding: 30px 15px;
    text-align: center;
  }
`;

const TitleContainer = styled.div`
  grid-column: 1;
  grid-row: 2;
  font-style: normal;
  line-height: 130%;
  text-transform: uppercase;

  @media screen and (max-width: ${MOBILE_BREAKPOINT}) {
    grid-column: 1 / 3;
  }
`;

const Title = styled.h2`
  margin-bottom: 20px;
  font-family: 'Oswald';
  font-weight: 500;
  font-size: 36px;
  color: #2d2b2c;

  @media screen and (max-width: 768px) {
    margin-bottom: 10px;
    font-size: 24px;
  }
`;

const Subtitle = styled.h3`
  margin-bottom: 20px;
  font-family: 'Lato';
  font-weight: bold;
  font-size: 18px;
  color: #a1c654;

  @media screen and (max-width: 768px) {
    margin-bottom: 10px;
    font-size: 14px;
  }
`;

const Content = styled.div`
  grid-column: 1;
  grid-row: 3 / 5;

  @media screen and (max-width: ${MOBILE_BREAKPOINT}) {
    grid-column: 1 / 3;
    grid-row: 4;
  }
`;

const Descriprion = styled.div`
  margin-bottom: 40px;
  padding-right: 80px;
  font-family: 'Lato';
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 130%;
  color: #757575;
  @media screen and (max-width: ${MOBILE_BREAKPOINT}) {
    padding: 0 15px;
  }
`;

const ImageContainer = styled.div`
  grid-column: 2;
  grid-row: 1 / 6;
  display: flex;
  justify-content: center;
  align-items: center;
  @media screen and (max-width: ${MOBILE_BREAKPOINT}) {
    grid-column: 1 / 3;
    grid-row: 3;
  }
`;

export default function BannerJoin({ title, subtitle, description, image }) {
  const { setIsModalOpen } = useContext(ModalsContext);

  return (
    <Container>
      <TitleContainer>
        <Title>{title}</Title>
        <Subtitle>{subtitle}</Subtitle>
      </TitleContainer>
      <Content>
        <Descriprion>
          {description.map((paragraph, key) => (
            <p key={key}>{paragraph}</p>
          ))}
        </Descriprion>
        <button
          className="hoc-button hoc-button--inline-block hoc-button--hover-dark"
          onClick={() => setIsModalOpen(true)}
        >
          Join us
        </button>
      </Content>
      <ImageContainer>
        <GatsbyImage image={image} alt="" />
      </ImageContainer>
    </Container>
  );
}
